import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import CustomPagination from './CustomPagination';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import ReactDatatable from '@ashvin27/react-datatable';
import { getAllbrandslistAction, getLeadsListAction, LeadBlockUnBlockAction, LeadDeleteAction, getTotalLeadsListAction, LeadUploadExcelAction,getleadTypeAction, getAllLeadtypesAction, getAllprioritylistAction, getAllmediumlistAction, getAllUsersListAction, LeadUploadOtherExcelAction } from '../Action/action';
import moment from 'moment';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CSVLink } from 'react-csv';
import Cookies from 'js-cookie';
import * as XLSX from 'xlsx';
const loginData = (!Cookies.get('loginSuccessltsAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessltsAdmin'));

const Leads = () => {
    const [leadsList, setleadsList] 				= useState({});
    const [leadTypes, setleadTypes] 				= useState([]);
    const [AllleadTypes, setAllleadTypes] 			= useState([]);
    const [CSVData, setCSVData] 					= useState([]);
    const [AllBrandsUsers, setAllBrandsUsers] 		= useState([]);
	const [page, setPage] 							= useState(1);
	const [startIndex, setstartIndex] 				= useState(0);	
	const [leadtype, setLeadType] 					= useState(0);
	const [priority_id, setPriorityid] 				= useState('');
	const [AssigntoUser, setAssigntoUser] 			= useState('');	
	const [medium_id, setMediumid] 					= useState('');
	const [Brands, setBrands] = useState([]);
	const [brand_id, setBrandid] 					= useState('');
	const [priorityFilter, setpriorityFilter] 		= useState('');
	const [mediumFilter, setmediumFilter] 			= useState('');
	const [nameFilter, setnameFilter] 				= useState('');
	const [emailFilter, setemailFilter] 				= useState('');
	const [leadtypeFilter, setleadtypeFilter] 		= useState('');
	const [enddateFilter, SetenddateFilter] 		= useState('');	
	const [startdateFilter, SetstartdateFilter] 	= useState('');	
	const [duplicateFileUrl, SetduplicateFileUrl] 	= useState('');	
	const [duplicates, Setduplicates] 				= useState('');	
	const [totalPages, settotalPages] 				= useState(0);
	const [totalRecords, settotalRecords] 			= useState(0);
	const [file, setFile] 							= useState(null);
	const [otherfile, setotherFile] 				= useState(null);
    const [spinloader, setspinloader] 				= useState(0);  
    const [pagingspinloader, setpagingspinloader] 	= useState(1);  
    const [uploadspinloader, setuploadspinloader] 	= useState(0); 
    const [filterspinloader, setfilterspinloader] 	= useState(0); 
    const [modalopen, setModalopen] 				= useState(0);
    const [othermodalopen, setOtherModalopen] 		= useState(0);
    const [isDialogOpen, setDialogOpen] 			= useState(false);	
    const [mediumlist, setmediumList] 				= useState([]); 
    const [prioritylist, setpriorityList] 			= useState([]);
    const [excelData, setExcelData] = useState(null);
    const [otherexcelData, setotherExcelData] = useState(null);
    const [mappingData, setMappingData] = useState([]);
    const dbcolumnsData = [		
		{ id:1, dbColumn: 'first_name' },
		{ id:2, dbColumn: 'last_name' },
		{ id:3, dbColumn: 'linkedin_url' },
		{ id:4, dbColumn: 'designation' },
		{ id:5, dbColumn: 'organisation' },
		{ id:6, dbColumn: 'geography' },
		{ id:7, dbColumn: 'phone' },
		{ id:8, dbColumn: 'email' },		
		{ id:9, dbColumn: 'sales_navigator_url' },		
		{ id:10, dbColumn: 'personal_email' },		
		{ id:11, dbColumn: 'corporate_phone' },		
		{ id:12, dbColumn: 'website' },		
	  ];
    
    useEffect(() => {
		setspinloader(1);
		setpagingspinloader(1);
        getleadTypes();
        getpriorityAPI();
        getmediumAPI();
        getBrandsAPI()    
        getAllleadTypesAPI();
		  if (!loginData?.email) {
            window.location.href = `${config.baseUrl}login`
        }        
        getLeadsList();
        getTotalLeadsList();
        getAllUsersListAPI()  
       
    }, [])
    
   const getAllleadTypesAPI = async () => {
        let res = await getAllLeadtypesAction();
        if (res.success) {
           setAllleadTypes(res.data)
        }
    } 
   const inputLeadtypeHandler = e => {
        const { name, value, id } = e.target        
        setLeadType(value)         
    }
   const inputHandler = e => {
        const { name, value, id } = e.target        
        setAssigntoUser(value)         
    }
   const inputpriorityFilterHandler = e => {
        const { name, value, id } = e.target        
        setpriorityFilter(value)         
    }
   const inputmediumFilterHandler = e => {
        const { name, value, id } = e.target        
        setmediumFilter(value)         
    }
   const inputnameFilterHandler = e => {
        const { name, value, id } = e.target        
        setnameFilter(value); 
    }
   const inputemailFilterHandler = e => {
        const { name, value, id } = e.target        
        setemailFilter(value);         
    }
   const inputleadtypeFilterHandler = e => {
        const { name, value, id } = e.target        
        setleadtypeFilter(value)         
    }
   const inputMediumidHandler = e => {
        const { name, value, id } = e.target        
        setMediumid(value)         
    }
   const inputbrandidHandler = e => {
        const { name, value, id } = e.target        
        setBrandid(value)         
    }
  function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }    
  const handleChangeStartDate = e => {
        let startyDate = formatDate(e);
       SetstartdateFilter(startyDate);
    }
   const handleStartDateKeyDown = (e) => {
    
    if (e.key === 'Backspace') {
      // Clear the selected date
      SetstartdateFilter('');
    }
  }; 
   const handleEndDateKeyDown = (e) => {
    
    if (e.key === 'Backspace') {
      // Clear the selected date
      SetenddateFilter('');
    }
  }; 
   const handleChangeEndDate = e => {
        let endDate = formatDate(e);
       SetenddateFilter(endDate);
    }
   const inputPriorityidHandler = e => {
        const { name, value, id } = e.target        
        setPriorityid(value)   
        if(parseInt(value) <= 4 )
        {
			setMediumid(1) 
		}      
        else if(parseInt(value) === 5 )
        {
			setMediumid(2) 
		}      
    }
   const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	  };
   const getpriorityAPI = async () => {
		let res = await getAllprioritylistAction();
		if (res.success) {
		  setpriorityList(res.data);
		}
	  };
   const getBrandsAPI = async () => {
        let res = await getAllbrandslistAction();
        if (res.success) {
			 setBrands(res.data)
            
        }
    }      
   const getmediumAPI = async () => {
		let res = await getAllmediumlistAction();
		if (res.success) {		 
		  setmediumList(res.data);
		}
	  };
   const getAllUsersListAPI = async () => {
        let res = await getAllUsersListAction();
        if (res.success) {
            setAllBrandsUsers(res.data)            
        }
    } 
   const handleUpload = async () => {
    const formData = new FormData();
    if(file ==null || leadtype ==0 || leadtype ==null || priority_id ==''|| medium_id =='' || brand_id =='' || AssigntoUser == '')
    {
		 if(file ==null)
		 {
			  toast.error("Excel file is required."); 
		 }
		 else if(leadtype ==0 || leadtype ==null)
		 {
			  toast.error('Please select lead type'); 
		 }
		 else if(priority_id == '')
		 {
			  toast.error('Please select priority'); 
		 }
		 else if(medium_id == '')
		 {
			  toast.error('Please select medium'); 
		 }
		 else if(brand_id == '')
		 {
			  toast.error('Please select brand'); 
		 }
		  else if(AssigntoUser == '')
		 {
			  toast.error('Please select user'); 
		 }
		
	}
	else
	{
		setuploadspinloader(1);
		formData.append('file', file);
		formData.append('leadtype', leadtype);
		formData.append('priority_id', priority_id);
		formData.append('medium_id', medium_id);
		formData.append('AssigntoUser', AssigntoUser);
		formData.append('brand_id', brand_id);
		let res = await LeadUploadExcelAction(formData);
		if (res.success) {
		 getLeadsList();		
		 toast.success(res.msg);
		 setuploadspinloader(0);
		 if (res.duplicateFileUrl) {
			 let URL= config.apiUrl+res.duplicateFileUrl;
			 Setduplicates(res.duplicates);
			 SetduplicateFileUrl(URL);
		}else{
				 setTimeout(() => {
					window.location.href = `${config.baseUrl}leads`;
				}, 3000);
			}
		} else {
			 toast.error(res.msg);
			 setuploadspinloader(0);
		}
		
	}  
  };
   const handleotherUpload = async () => {
	    Swal.fire({
            title: 'Are you sure?',
            text: "You want to import to database ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Import it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
				if(otherfile ==null || leadtype ==0 || leadtype ==null || priority_id ==''|| medium_id =='' || brand_id =='' || AssigntoUser == '')
				{
					 if(otherfile ==null)
					 {
						  toast.error("Excel file is required."); 
					 }
					 else if(leadtype ==0 || leadtype ==null)
					 {
						  toast.error('Please select lead type'); 
					 }
					 else if(priority_id == '')
					 {
						  toast.error('Please select priority'); 
					 }
					 else if(medium_id == '')
					 {
						  toast.error('Please select medium'); 
					 }
					 else if(brand_id == '')
					 {
						  toast.error('Please select brand'); 
					 }
					  else if(AssigntoUser == '')
					 {
						  toast.error('Please select user'); 
					 }
					
				}
				else
				{
					setuploadspinloader(1);
					formData.append('file', otherfile);
					formData.append('leadtype', leadtype);
					formData.append('priority_id', priority_id);
					formData.append('medium_id', medium_id);
					formData.append('AssigntoUser', AssigntoUser);
					formData.append('brand_id', brand_id);
					formData.append('mappingData', JSON.stringify(mappingData));
					let res = await LeadUploadOtherExcelAction(formData);
					if (res.success) {
						 getLeadsList();		
						 toast.success(res.msg);
						 setuploadspinloader(0);
						 if (res.duplicateFileUrl) {
							 let URL= config.apiUrl+res.duplicateFileUrl;
							 Setduplicates(res.duplicates);
							 SetduplicateFileUrl(URL);
						}else{
							 setTimeout(() => {
								window.location.href = `${config.baseUrl}leads`;
							}, 3000);
						}
					} else {
						 toast.error(res.msg);
						 setuploadspinloader(0);
					}
					
				}  
  
            }
        })
   };
   const handleFilter = async () => {
	   if(priorityFilter != '' || mediumFilter !=''|| nameFilter !=''|| emailFilter !='' || leadtypeFilter !=''|| startdateFilter !=''|| enddateFilter !='')
	   {
		   setPage(1);
		   getLeadsList();
		   getTotalLeadsList();
	   }
	   else
	   {
		   toast.error('Please select atleast one option.');
	   }
	};
   const getleadTypes = async () => {		
        let res = await getleadTypeAction();
        if (res.success) {
            setleadTypes(res.data);
            setspinloader(0);
        }        
    }
   const getLeadsList = async () => {
        let res = await getLeadsListAction({userId:loginData?.id,page:page,priority:priorityFilter,medium:mediumFilter,name:nameFilter,email:emailFilter,leattype:leadtypeFilter,startDate:startdateFilter,endDate:enddateFilter});
        if (res.success) {
            setleadsList(res.data);

            if (res.data.length > 0) {
                let csvData = res.data;
                let csvAllData = [];
                for (let i = 0; i < csvData.length; i++) {
                    if (i == 0) {
                        csvAllData[i] = ['First Name', 'Last Name', 'Professional Email', 'Phone','LinkedIn URL', 'Designation', 'Organisation', 'Geography', 'Status', 'Stage', 'Brand', 'User', 'Created On'];
                    }
                    csvAllData[i + 1] = [csvData[i].first_name, csvData[i].last_name, csvData[i].email,csvData[i].phone, csvData[i].linkedin_url,csvData[i].designation,csvData[i].organisation,csvData[i].geography,csvData[i].status,csvData[i].stage_title,csvData[i].brand_name, csvData[i].user, moment(csvData[i].created_on).format('DD/MM/YYYY')];
                }
                setCSVData(csvAllData);
            }
        }
        else
        {
			setleadsList({});	
		}
    }
   const SrNo = (index) => {
		return startIndex + index + 1;
	  };
   const onPageChanged = async (data)  => {
		const { currentPage, totalPages, pageLimit } = data;
		const offset = (currentPage - 1) * pageLimit;	
		setstartIndex(offset);	
		let res = await getLeadsListAction({userId:loginData?.id, page:offset, priority:priorityFilter, medium:mediumFilter,name:nameFilter,email:emailFilter,leattype:leadtypeFilter, startDate:startdateFilter,endDate:enddateFilter});
        if (res.success) {
            setleadsList(res.data);

            if (res.data.length > 0) {
                let csvData = res.data;
                let csvAllData = [];
                for (let i = 0; i < csvData.length; i++) {
                    if (i == 0) {
                        csvAllData[i] = ['First Name', 'Last Name', 'Professional Email', 'Phone','LinkedIn URL', 'Designation', 'Organisation', 'Geography', 'Status', 'Stage', 'Brand', 'User', 'Created On'];
                    }
                    csvAllData[i + 1] = [csvData[i].first_name, csvData[i].last_name, csvData[i].email,csvData[i].phone, csvData[i].linkedin_url,csvData[i].designation,csvData[i].organisation,csvData[i].geography,csvData[i].status,csvData[i].stage_title,csvData[i].brand_name, csvData[i].user, moment(csvData[i].created_on).format('DD/MM/YYYY')];
                }
                setCSVData(csvAllData);
            }
        }
        else
        {
			setleadsList({});	
		}

  }	
   const getTotalLeadsList = async () => {
		settotalPages(0);
		settotalRecords(0);
		setpagingspinloader(0);	
        let res = await getTotalLeadsListAction({userId:loginData?.id,priority:priorityFilter,medium:mediumFilter,name:nameFilter,email:emailFilter,leattype:leadtypeFilter,startDate:startdateFilter,endDate:enddateFilter});
        if (res.success) {
            settotalPages(res.data);
            settotalRecords(res.totalRecords);
            setpagingspinloader(0);
		}
        else
        {
			settotalPages(0);
			settotalRecords(0);
			setpagingspinloader(0);	
		}
		
		
    }
   const leadBlockUnBlock = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to Disable this Lead!" : "You want to Enable this Lead!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: is_active == 0 ? 'Yes, Disable it!' : 'Yes, Enable it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await LeadBlockUnBlockAction({ 'id': id, 'is_active': is_active });
                if (res.success) {
                    getLeadsList();
                    Swal.fire(
                        is_active == 0 ? 'Disabled!' : 'Enabled!',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    }
   const LeadDelete = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete this lead ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await LeadDeleteAction({ 'id': id });
                if (res.success) {
                    getLeadsList();
                    Swal.fire(
                        'Deleted!',
                        res.msg,
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                        'error'
                    )
                }
            }
        })
    }
   const copieBtn = async () => {
        toast.success(`Coppied!!`);
    }   
   const closebutton = async () => {
		setMediumid('');
		setPriorityid('');
		setLeadType('');
		setBrandid('');
        setModalopen(0);
    } 
   const closeotherbutton = async () => {
		setOtherModalopen(0);
    } 
   function afterOpenModal() {
        //subtitle.style.color = '#f00';
    }
   const importLead = () => {	
	   setFile(null);       
	   setModalopen(1);        
    }  
   const otherimportLead = () => {	 
	   setotherFile(null);   
	   setOtherModalopen(1);        
    }  
   const handleFileUpload = (e) => {
    const file = e.target.files[0];  
     if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {        
		if (file) {
		  setFile(e.target.files[0]);
		  const reader = new FileReader();		  
		  const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
		  const parts = fileNameWithoutExtension.split('_'); 
		  if (parts.length === 4) {			    
			  const import_brand = Brands.find(brand => brand.brand_name.toLowerCase() === parts[0].toLowerCase());
			  const import_type = leadTypes.find(Type => Type.title.toLowerCase() === parts[1].toLowerCase());
			  const import_priorty = prioritylist.find(priority => priority.title.toLowerCase() === parts[2].toLowerCase());
			  const import_medium = mediumlist.find(medium => medium.title.toLowerCase().includes(parts[3].toLowerCase()));
			 
			  setBrandid(import_brand.brand_id);
			  setLeadType(import_type.id); 
			  setPriorityid(import_priorty.id)   
			  setMediumid(import_medium.id) 			  
				
				if(loginData?.role != config.superAdmin)	
				{
					setAssigntoUser(loginData?.id) 
				}
				
			  reader.onload = (e) => {
				const data = e.target.result;
				const workbook = XLSX.read(data, { type: 'binary' });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
				
				
				setExcelData(jsonData);
			  };

			  reader.readAsBinaryString(file);
		  } else {
			setExcelData(null);
			setFile(null);
			toast.error('Please rename the file as per the format to upload.');
		  }
		}
	} else {
		setExcelData(null);
		setFile(null);
        toast.error('Invalid file type. Please select a valid Excel file.');
      } 
  };
   const handleotherFileUpload = (e) => {
    const file = e.target.files[0];  
     if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {        
		if (file) {
		  setotherFile(e.target.files[0]);
		  const reader = new FileReader();		 
			if(loginData?.role != config.superAdmin)	
			{
				setAssigntoUser(loginData?.id) 
			}
			
		  reader.onload = (e) => {
			const data = e.target.result;
			const workbook = XLSX.read(data, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const headers = XLSX.utils.sheet_to_json(sheet, { header: 1 })[0];  // get header
			const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1,defval: '', });
			setLeadType(5); 
			// code for mapping header			
			 const formattedHeaders = headers.map((header) => ({
				  originalHeader: header,
				  formattedHeader: header.toLowerCase().replace(/\s+/g, '_'),
				}));
			const newMappingData = formattedHeaders.map((header, index) => ({
				  id: index,
				  excelColumn: header.formattedHeader,
				  dbColumn: '',
				  originalHeader: header.originalHeader,
				}));
			const filteredData = jsonData.filter(
			  (row) => row.some((cell) => cell !== '' && cell !== null && cell !== undefined)
			);	
			setMappingData(newMappingData);
			setotherExcelData(filteredData);
			
		  };
		  reader.readAsBinaryString(file);
		 
		}
	} else {
		setotherExcelData(null);
		setotherFile(null);
        toast.error('Invalid file type. Please select a valid Excel file.');
      } 
  };
   const handleColumnChange = (id, columnType, value) => {
    setMappingData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, [columnType]: value } : item
      )
    );
  };
    return (

        <>
            <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Leads List</h3>
                                 </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
											<div className="row mb-15">
											<h4 className="box-title col-md-2">Leads ({leadsList.length})</h4>
                                            
											  <div className="col-md-10 text-right">
											   <a href={`${config.baseUrl}Addleads`}
										className="btn btn-sm btn-primary add_btn">Add Lead</a>
												<a href='javascript:;' onClick={() => { importLead() }} className="btn btn-sm btn-primary add_btn mr-15">Import Leads</a>
												<a href='javascript:;' onClick={() => { otherimportLead() }} className="btn btn-sm btn-primary add_btn mr-15">Other Source Import Leads</a>
												
                                            
											</div>
                                        </div>
                                         <div className="row">
                                            <div className="col-md-3 mb-3">
												<input type="text" name="nameFilter" class="form-control lh25" id="nameFilter" onChange ={inputnameFilterHandler} placeholder="Enter name"/>												
                                             </div>
                                            <div className="col-md-3 mb-3">
												<input type="text" name="emailFilter" class="form-control lh25" id="emailFilter" onChange ={inputemailFilterHandler} placeholder="Enter email address" />	
                                             </div>
                                             <div className="col-md-2 mb-3">
												<select name='leadtypeFilter' className="form-control lh25" id='leadtypeFilter' onChange={inputleadtypeFilterHandler}>
													<option value="">Lead type</option>
													{leadTypes.map(leadtyp => (
													  <option key={leadtyp.id} value={leadtyp.id}>{leadtyp.title}</option>
													))}										
												</select>
                                             </div>
                                             <div className="col-md-2 mb-3">
												<select name='priorityFilter' className="form-control lh25" id='priorityFilter' onChange={inputpriorityFilterHandler} value={priorityFilter}>
													<option value="">Priority</option>
													{prioritylist.map(priority => (
														<option value={priority.id}>{priority.title}</option>
													))}												
												</select>
                                             </div>
                                             <div className="col-md-2 mb-3">
                                             <select name='mediumFilter' className="form-control lh25" id='mediumFilter' onChange={inputmediumFilterHandler} value={mediumFilter}>
													<option value="">Medium</option>
													{mediumlist.map(medium => (
														<option value={medium.id}>{medium.title}</option>
													))}												
												</select> 
                                             </div>
                                             <div className="col-md-2 mb-3">
                                             <DatePicker onChange={handleChangeStartDate}  placeholderText ="Select Start Date" autoComplete="off" name="start_date" id="start_dateError" className="form-control lh25 cleander-icon" value={startdateFilter}  onKeyDown={handleStartDateKeyDown} />
                                             </div>
                                             <div className="col-md-2 mb-3">
                                            <DatePicker onChange={handleChangeEndDate}  placeholderText ="Select End Date" autoComplete="off" name="end_date" id="end_dateError" className="form-control lh25 cleander-icon" value={enddateFilter} onKeyDown={handleEndDateKeyDown} />
                                             </div>
                                             
                                                                                         
											  <div className="col-md-2">	
											  <a href={`${config.baseUrl}leads`}
										className="btn btn-sm btn-primary add_btn">Clear <i className="fa fa-eraser" aria-hidden="true"></i></a>	
											  	{ filterspinloader == '0' ?
													  <>													  
												   <button className="btn btn-sm btn-primary add_btn mr-15" onClick={handleFilter}>Filter <i className="fa fa-filter" aria-hidden="true"></i></button>
												   </>
												   :
													<>
													 <button disabled className="btn btn-sm btn-primary add_btn mr-15" >Geting... &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
													  </>
												  }	
											   
                                                                                        
									   </div>								  
                                        </div>
                                         
                                        </div>
                                        <div className="box-body">
                                            
                                           <div className="table-responsive">
											  <table className="table table-bordered">
												<thead>
												  <tr>
													<th>#</th>
													<th>Lead Type</th>
													<th>Name</th>
													<th>Email</th>
													<th>Phone</th>									
													<th>Priority</th>									
													<th>Medium</th>									
													<th>Linkedin</th>
													<th>Designation</th>
													<th>Organisation</th>
													<th>Geography</th>
													<th>Stage</th>
													<th>DND</th>
													<th>Created Date</th>
													<th>Action</th>
												  </tr>
												</thead>
												<tbody>											
												
												 {leadsList.length> 0 ?
													  leadsList.map((item, index) => (
													 <>
														 <tr>
															<td>{SrNo(index)}</td>
															<td>{item.leadtype}</td>
															<td>{item.first_name} {item.last_name}</td>
															<td> <a href="#"> {item.email.trim()}</a> 
															&nbsp; 
															{item.email.trim() ? (
																<>
																	<CopyToClipboard text={item.email}>
																		<span 
																			title="Click to Copy" 
																			className="mr-copylink" 
																			id="token-buy-button" 
																			onClick={copieBtn} 
																			style={{ cursor: "pointer", color: 'rgb(157 81 255)' }}>
																			<i className="fa fa-copy "></i>
																		</span>
																	</CopyToClipboard>
																</>
															) : null}
			
																</td>
															<td>{item.phone}</td>
															<td>{item.priority_title}</td>
															<td>{item.medium_title}</td>
															<td>&nbsp; 
															{item.linkedin_url && item.linkedin_url.trim() ? (
																<>
																	<CopyToClipboard text={item.linkedin_url}>
																	<span title="Click to Copy" className="mr-copylink" id="token-buy-button" onClick={copieBtn} style={{ cursor: "pointer", color: 'rgb(157 81 255)' }}><i className="fa fa-copy "></i></span>
																</CopyToClipboard>
																</>
															) : null}
															
															</td>
															<td>{item.designation}</td>
															<td>{item.organisation}</td>
															<td>{item.geography}</td>
															<td>{item.stage_title}</td>
															<td>{ item.is_dnd==1 ? 'Yes': 'No' }</td>
															<td>{moment(item.created_on).format('DD/MM/YYYY')}</td>
															<td>	
															 <div className="btn-group mb-5">
																{item.status === 0 ?
																	<button type="button" className="waves-effect waves-light btn btn-primary btn-sm " onClick={() => leadBlockUnBlock(item.id, 1)}><i className='fa fa-unlock'></i> Inactive</button>
																	: item.status === 1 ?
																		<button type="button" className="waves-effect waves-light btn btn-primary btn-sm " onClick={() => leadBlockUnBlock(item.id, 0)}><i className='fa fa-ban'></i> Active</button>
																		:
																		''
																}
																&nbsp;&nbsp; <Link to={`${config.baseUrl}editLeads/` + item.id}>
																		<button className='btn-sm btn-primary'>Edit</button>
																	</Link> &nbsp;
															   {loginData.role==1 ?							        
																   <>
																   &nbsp;&nbsp; <button type="button" className="waves-effect waves-light btn btn-primary btn-sm " onClick={() => LeadDelete(item.id)}><i className='fa fa-trash'></i> Delete</button>
																   </>
																   :''
															   }
															</div>
															</td>
															
														  </tr>	
													  </>
													)) 
													:''
												 }
												</tbody>
											  </table>	
											 {totalPages > 0 ?										  
											 <CustomPagination totalRecords={totalRecords} totalPages={totalPages} pageNeighbours={page} onPageChanged={onPageChanged}   />
											 : ''
										 }	
																				
											</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>                       
                    </div>
                </div>
                
          <div className={modalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: modalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-lg">
			<div className="modal-content">
			  <div className="modal-header">
				<h5 className="modal-title" id="exampleModalLiveLabel">Import Leads</h5>				
				<button className="btn-close" type="button" onClick={closebutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row">	
			  <div className="col-md-12 mb-15"> 
			  <p className="error"><strong>Note:</strong> Please keep the file name in the format: Brand_Source_Segment_Medium.xlsx</p>   
			  </div>              
			  <div className="col-md-12 mb-15">                  
				 <input name="uploadExcel" id="uploadExcel" className="form-control lh25" type="file" onChange={handleFileUpload} />											
				 </div>
				  </div>
				  {excelData && (
					  <div>
						<div className="row">
						  <div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>
							{spinloader == '0' && (
							  <select name='lead_type' className="form-control lh25" id='lead_type' onChange={inputLeadtypeHandler} value={leadtype}>
								<option value="">Lead type</option>
								{leadTypes.map(leadtyp => (
								  <option key={leadtyp.id} value={leadtyp.id}>{leadtyp.title}</option>
								))}
							  </select>
							)}
						  </div>
						  <div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='priority_id' className="form-control lh25" id='priority_id' onChange={inputPriorityidHandler} value={priority_id}>
											<option value="">Priority</option>
											{prioritylist.map(priority => (
												<option value={priority.id}>{priority.title}</option>
											))}												
										</select> 
										</>
										:
										''
										}  
									</div>               
									<div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='medium_id' className="form-control lh25" id='medium_id' onChange={inputMediumidHandler} value={medium_id}>
											<option value="">Medium</option>
											{mediumlist.map(medium => (
												<option value={medium.id}>{medium.title}</option>
											))}												
										</select> 
										</>
										:
										''
										}  
									</div>
									<div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='brand_id' className="form-control lh25" id='brand_id' onChange={inputbrandidHandler} value={brand_id}>
											<option value="">Brand</option>
											{Brands.map(Brand => (
												<option value={Brand.brand_id}>{Brand.brand_name}</option>
											))}											
										</select> 
										</>
										:
										''
										}  
									</div> 
									{loginData?.role === config.superAdmin ? (
									<>
									<div className="col-md-4 mb-15">                 
									  {spinloader == '0' ? 
										  <>
										<select name='user_id' className="form-control lh25" id='user_idError' onChange={inputHandler}>
											<option value="">User</option>
											{AllBrandsUsers.map(Usr => (
												<option value={Usr.id}>{Usr.email}</option>
											))}
										</select>
										</>
										:
										''
										}  
									</div>  
								</>
								) : ''}
						</div>

						<div className="excel-info-table">
						  <table className="table table-bordered">
							<thead>
							  <tr>
								{excelData[0].map((header, index) => (
								  <th key={index}>{header}</th>
								))}
							  </tr>
							</thead>
							<tbody>
							  {excelData.slice(1).map((row, rowIndex) => (
								<tr key={rowIndex}>
								  {row.map((cell, cellIndex) => (
									<td key={cellIndex}>{cell}</td>
								  ))}
								</tr>
							  ))}
							</tbody>
						  </table>
						</div>						
						
						<div className="row">
						<div className="col-md-8 mb-15 mt-15">
						{duplicateFileUrl !='' ? (
							<>
							<p className="mt-4">
							{duplicates} duplicate records found. <a href={duplicateFileUrl} download>
    <span>Click here</span></a> to download.</p>
							</> 
						):null}
						 </div>
						  <div className="col-md-4 mb-15 mt-15">
							{uploadspinloader == '0' ? (
							  <button className="btn btn-sm btn-primary import_btn" onClick={handleUpload}>
								Import to DB <i className="fa fa-file-excel-o" aria-hidden="true"></i>
							  </button>
							) : (
							  <button disabled className="btn btn-sm btn-primary import_btn">
								Importing &nbsp; <i className="fa fa-spinner fa-spin validat"></i>
							  </button>
							)}
						  </div>
						</div>
					  </div>
				)}

								
			  </div>
			  </div>			 
			</div>
		  </div>
		  
		  <div className={othermodalopen == '0' ? "modal fade cart-modal mc-popup" : "mc-popup modal fade cart-modal show"} role="dialog" style={{ background: '0% 0% / cover rgba(6, 6, 6, 0.32)', display: othermodalopen == '0' ? 'none' : 'block' }}  id="myModal" tabindex="-1" data-backdrop="false">
		  <div className="modal-dialog modal-lg">
			<div className="modal-content">
			  <div className="modal-header">
				<h5 className="modal-title" id="exampleModalLiveLabel">Other Source Import Leads</h5>
				<button className="btn-close" type="button" onClick={closeotherbutton} aria-label="Close"></button>
			  </div>
			  <div className="modal-body">
			  <div className="row">				   
			  <div className="col-md-12 mb-15">                  
				 <input name="uploadExcel" id="uploadExcel" className="form-control lh25" type="file" onChange={handleotherFileUpload} />											
				 </div>
				  </div>
				  {otherexcelData && (
					  <div>						
						<div className="excel-info-table2">
						  <table className="table table-bordered">
							<thead>
							  <tr>
								{otherexcelData[0].map((header, index) => (
								  <th className="lh25" key={index}>{header}</th>
								))}
							  </tr>
							</thead>
							<tbody>
							  {otherexcelData.slice(1).map((row, rowIndex) => (
								<tr key={rowIndex}>
								  {row.map((cell, cellIndex) => (
									<td className="lh25" key={cellIndex}>{cell}</td>
								  ))}
								</tr>
							  ))}
							</tbody>
						  </table>
						</div>
						</div>
				)}
				 {otherexcelData && (
					  <div className="mt-15">						
						<div className="excel-info-table3">
							<h5>Please match the columns</h5>
						  <table className="table table-bordered">	
						   <thead>
								<tr>
								  <th className="lh25">Excel column</th>
								  <th className="lh25">DB column</th>								 
								</tr>
							  </thead>						
							<tbody>
							 {mappingData.map((row) => (
								  <tr key={row.id}>									
									<td className="lh25">{row.originalHeader}</td>
									<td className="lh25">
									  <select
										id={`excel_column_${row.id}`}
										name={`excel_column_${row.id}`}
										value={row.dbColumn}
										className="form-control lh25"
										onChange={(e) =>
										  handleColumnChange(row.id, 'dbColumn', e.target.value)
										}
										
									  >
										<option value="">Select excel column</option>
										{dbcolumnsData.length > 0 &&
										  dbcolumnsData.map((header) => (
											<option key={header.id} value={header.dbColumn}>
											  {header.dbColumn}
											</option>
										  ))}
									  </select>
									</td>
								  </tr>
								))}
							</tbody>
						  </table>
						</div>
						
						<div className="row mt-15">
						  <div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='priority_id' className="form-control lh25" id='priority_id' onChange={inputPriorityidHandler} value={priority_id}>
											<option value="">Priority</option>
											{prioritylist.map(priority => (
												<option value={priority.id}>{priority.title}</option>
											))}												
										</select> 
										</>
										:
										''
										}  
									</div>               
									<div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='medium_id' className="form-control lh25" id='medium_id' onChange={inputMediumidHandler} value={medium_id}>
											<option value="">Medium</option>
											{mediumlist.map(medium => (
												<option value={medium.id}>{medium.title}</option>
											))}												
										</select> 
										</>
										:
										''
										}  
									</div>
									<div className={loginData?.role != config.superAdmin ? 'col-md-3 mb-15' : 'col-md-2 mb-15'}>                 
									  {spinloader == '0' ? 
										  <>
										<select name='brand_id' className="form-control lh25" id='brand_id' onChange={inputbrandidHandler} value={brand_id}>
											<option value="">Brand</option>
											{Brands.map(Brand => (
												<option value={Brand.brand_id}>{Brand.brand_name}</option>
											))}											
										</select> 
										</>
										:
										''
										}  
									</div> 
									{loginData?.role === config.superAdmin ? (
									<>
									<div className="col-md-4 mb-15">                 
									  {spinloader == '0' ? 
										  <>
										<select name='user_id' className="form-control lh25" id='user_idError' onChange={inputHandler}>
											<option value="">User</option>
											{AllBrandsUsers.map(Usr => (
												<option value={Usr.id}>{Usr.email}</option>
											))}
										</select>
										</>
										:
										''
										}  
									</div>  
								</>
								) : ''}
						</div>

						<div className="row">
						<div className="col-md-8 mb-15 mt-15">
						{duplicateFileUrl !='' ? (
							<>
							<p className="mt-4">
							{duplicates} duplicate records found. <a href={duplicateFileUrl} download>
    <span>Click here</span></a> to download.</p>
							</> 
						):null}
						 </div>
						  <div className="col-md-4 mb-15 mt-15 pull-right">
							{uploadspinloader == '0' ? (
							  <button className="btn btn-sm btn-primary import_btn" onClick={handleotherUpload}>
								Import to DB <i className="fa fa-file-excel-o" aria-hidden="true"></i>
							  </button>
							) : (
							  <button disabled className="btn btn-sm btn-primary import_btn">
								Importing &nbsp; <i className="fa fa-spinner fa-spin validat"></i>
							  </button>
							)}
						  </div>
						</div>
					  
						</div>
					)}			
			  </div>
			  </div>			 
			</div>
		  </div>
		  		
                <Footer />
            </div>
        </>


    )

}
export default Leads;
